import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"

const DynamicPage = ({ data, pageContext }) => {
  const { contentSections, metadata, localizations, slug } = data.strapiPage
  const global = data.strapiGlobal
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter

  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout
        navbar={navbar}
        footer={footer}
        pageContext={{ ...pageContext, localizations, slug }}
      >
        <Sections sections={contentSections} />
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      metaKeywords
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    strapiPage(id: { eq: $id }) {
      slug
      shortName
      metadata {
        metaTitle
        metaDescription
        metaKeywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
    }
  }
`
